import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { EmojiHappyIcon, CheckCircleIcon } from "@heroicons/react/outline"
import Quote from "../components/quote"
import ProjectCta from "../components/projectCta"
import ServiceList from "../components/serviceList"
import { graphql, Link } from "gatsby"
import ReviewOverview from "../components/reviewOverview"

const IndexPage = ({
  data: {
    contentfulHome: {
      bannerLine1,
      bannerLine2,
      servicesTitle,
      bannerImage,
      workWithUsTitle,
      workWithUsBody,
      workWithUsBenefits,
      workWithUsImage,
      hereToHelpSuper,
      hereToHelpTitle,
      hereToHelpBody,
      hereToHelpImage,
      contactUsCtaText,
      featureTestimonial,
    },
  },
}) => {
  return (
    <Layout>
      <Seo title="Home" />

      <section className="relative mt-12">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100 " />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={bannerImage.file.url}
                alt={bannerImage.title}
              />
              <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="mx-auto text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl sm:max-w-3xl">
                <span className="block text-white">{bannerLine1}</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                {bannerLine2}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <Link
                    to="/contact"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-teal-700 bg-white hover:bg-teal-50 sm:px-8"
                  >
                    Get in touch
                  </Link>
                  <Link
                    to="/services"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-teal-600 bg-opacity-90 hover:bg-opacity-70 sm:px-8"
                  >
                    Our services
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServiceList title={servicesTitle} />

      <section className="relative bg-white">
        <div className="h-56 bg-teal-600 sm:h-72 lg:absolute lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="w-full h-full object-cover md:p-10 bg-white md:bg-gray-100"
            src={workWithUsImage.file.url}
            alt={workWithUsImage.file.title}
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-32">
          <div className="max-w-2xl mx-auto lg:max-w-none lg:ml-0 lg:mr-auto lg:w-1/2 lg:pr-10">
            <div>
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-gray-800 text-white">
                <EmojiHappyIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 sm:text-4xl">
              {workWithUsTitle}
            </h2>
            <p className="mt-6 text-lg text-gray-500">{workWithUsBody}</p>
            <div className="mt-8 overflow-hidden">
              <dl className="-mx-8 -mt-8 flex flex-wrap">
                {workWithUsBenefits.map(({ title, body, id }) => (
                  <div key={id} className="flex flex-col px-8 pt-8">
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-8 w-8">
                          <CheckCircleIcon
                            className="h-8 w-8 text-gray-600"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-12 text-lg leading-6 font-medium text-gray-900">
                          {title}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-12 text-base text-gray-500">
                        {body}
                      </dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section className="relative bg-gray-800">
        <div className="h-56 bg-teal-0 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src={hereToHelpImage.file.url}
            alt={hereToHelpImage.description}
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-56">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              {hereToHelpSuper}
            </h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              {hereToHelpTitle}
            </p>
            <p className="mt-3 text-lg text-gray-300">{hereToHelpBody}</p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/projects"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                  Our projects
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {featureTestimonial.testimonial && (
        <Quote
          projectLink={`/projects/${featureTestimonial.slug}`}
          testimonial={{
            text: featureTestimonial.testimonial.testimonial,
            address: featureTestimonial.address,
            customerName: featureTestimonial.customerName,
            image: featureTestimonial.featuredImage.file.url,
          }}
        />
      )}

      <ReviewOverview />

      <ProjectCta text={contactUsCtaText} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulHome {
      id

      bannerLine1
      bannerLine2
      bannerImage {
        id
        title
        file {
          url
        }
      }

      servicesTitle

      workWithUsTitle
      workWithUsBody
      workWithUsBenefits {
        id
        title
        body
      }
      workWithUsImage {
        id
        title
        file {
          url
        }
      }

      hereToHelpSuper
      hereToHelpTitle
      hereToHelpBody
      hereToHelpImage {
        id
        title
        file {
          url
        }
      }

      contactUsCtaText

      featureTestimonial {
        slug
        address
        testimonial {
          testimonial
        }
        featuredImage {
          file {
            url
          }
          description
        }
        customerName
      }
    }
  }
`

export default IndexPage
